import { useInviteUserMutation } from "@apis/users";
import { useAppAlerts } from "@components/AppAlerts";
import CommonDialog from "@components/CommonDialog";
import LoadingButton from "@components/LoadingButton";
import { Button, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import { useState } from "react";

function InviteUserModal(props: { open: boolean; onClose: () => void }) {
  const { addAlert } = useAppAlerts();
  const [email, setEmail] = useState('');
  const { mutateAsync: inviteUser, isPending } = useInviteUserMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await inviteUser({ email });
      addAlert({ message: `Sent an email invite to ${email}`, type: 'success' });
      props.onClose();
    } catch (error: any) {
      addAlert({ message: error?.message || 'Failed to invite the user', type: 'error' });
    }
  }

  return (
    <CommonDialog heading="Invite User" open={props.open} onClose={props.onClose} fullWidth={false}>
      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ paddingTop: 0 }}>
          <Typography variant="body2" color="text.secondary" marginBottom={2}>Enter the email address of the user you want to invite.</Typography>
          <TextField
            autoFocus
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            slotProps={{
              inputLabel: {
                required: false,
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton isLoading={isPending} type="submit" variant="contained">Invite</LoadingButton>
        </DialogActions>
      </form>
    </CommonDialog>
  )
}

export default function InviteUserBtn() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>Invite</Button>
      <InviteUserModal open={open} onClose={() => setOpen(false)} />
    </>
  )
}
