import { usePostHog } from "posthog-js/react"
import { useCallback } from "react";

export const enum EEvent {
  USER_SIGNED_UP = 'user_signed_up',
  USER_SIGNED_IN = 'user_signed_in',
  USER_SIGNED_OUT = 'user_signed_out',
  USER_EMAIL_VERIFIED = 'user_email_verified',
  USER_EMAIL_VERIFICATION_SENT = 'user_email_verification_sent',
  USER_PASSWORD_RESET_REQUESTED = 'user_password_reset_requested',
  USER_PASSWORD_RESET = 'user_password_reset',

  INTEGRATION_CONNECTION_INITIATED = 'integration_connection_initiated',
  INTEGRATION_CONNECTION_SUCCESSFUL = 'integration_connection_successful',

}

type UserProperties = {
  email: string;
  first_name?: string;
  last_name?: string;
  org_name?: string;
}

export const useTrackEvents = () => {
  const posthog = usePostHog();

  const trackEvent = useCallback((event: EEvent, properties?: Record<string, unknown>) => {
    posthog.capture(event, properties);
  }, [posthog]);

  const identify = useCallback((userId: string, properties?: UserProperties) => {
    posthog.identify(userId, properties);
  }, [posthog]);

  const resetIdentity = useCallback(() => {
    posthog.reset();
  }, [posthog]);

  return {
    trackEvent,
    identify,
    resetIdentity,
  };
}

