import http from "@apis/http";
import { useAuthContext } from "@components/auth/auth.ctx";
import { EEvent, useTrackEvents } from "@instrumentation/useTrackEvents";
import { queryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { API_BASE_URL } from "@utils/env.values";

export type User = {
  id: string,
  is_active: boolean,
  is_admin: boolean,
  email: string,
  first_name: string,
  last_name: string,
  created_at: string,
  permissions: Record<string, unknown>,
  organization: {
    organization_name: string,
    organization_id: string,
    is_client: boolean,
    max_users: number,
  },
  tenant: {
    tenant_name: string,
    tenant_id: string,
  }
}

const authBaseURL = `${API_BASE_URL}/auth`

/**
 * Login with email and password
 */
type TLoginArgs = {
  email: string;
  password: string;
}

type TLoginResponse = User;

export const useLoginMutation = (onSuccess?: (data: TLoginResponse) => Promise<void>) => {
  const { trackEvent, identify } = useTrackEvents();
  const { setUser } = useAuthContext();

  return useMutation<TLoginResponse, Error, TLoginArgs>({
    mutationFn: (data: TLoginArgs) => http.post(`${authBaseURL}/login`, data),
    onSuccess: async (data) => {
      setUser(data);
      trackEvent(EEvent.USER_SIGNED_IN, { email: data.email });
      identify(data.id, {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        org_name: data.organization.organization_name,
      })
      await onSuccess?.(data);
    }
  })
}


/**
 * Get OAuth URL for sign in
 */
export enum OAuthProvider {
  SALESFORCE = 'salesforce',
  HUBSPOT = 'hubspot',
}

type GetConnectionOAuthUrlResponse = {
  oauth_url: string,
}

export function useOAuthURLSignInMutation(provider: OAuthProvider) {
  return useMutation<GetConnectionOAuthUrlResponse, Error>({
    mutationFn: () => http.get(`${authBaseURL}/signin/${provider}`),
  });
}

/**
 * Sign in with OAuth provider
 */
type SignInWithOAuthResponse = User;
type SignInWithOAuthArgs = {
  code: string;
}

export function useOAuthSignIn(provider: OAuthProvider, args: SignInWithOAuthArgs) {
  return useQuery<SignInWithOAuthResponse, Error>({
    queryKey: ['signin', provider],
    queryFn: () => http.post(`${authBaseURL}/signin/${provider}`, args),
    enabled: !!args.code,
    retry: false,
    refetchOnWindowFocus: false,
  });
}


/**
 * Get current logged in user
 */
export const getLoggedInUserQueryOptions = queryOptions<User, Error>({
  queryKey: ['user'],
  queryFn: () => http.get(`${authBaseURL}/user`),
})

/**
 * Logout
 */
type TLogoutResponse = {
  message: string;
  type: 'success' | 'error';
  logged_out: boolean;
}
export const logoutUserQueryOptions = queryOptions<TLogoutResponse, Error>({
  queryKey: ['logout'],
  queryFn: () => http.get(`${authBaseURL}/logout`),
})
