import logo from '@assets/revamp-logo.png';
import { MuiLink } from '@components/Link';
import { LinkedInTabs } from '@components/linkedin/utils';
import UserSettings from '@components/UserSettings';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Box, IconButton, Stack, Tooltip, useTheme } from "@mui/material";
import { useMatchRoute } from '@tanstack/react-router';


function SidebarItem({ icon, label, isActive }: { icon: React.ReactNode, label: string, isActive: boolean }) {
  const theme = useTheme();
  return (
    <Tooltip title={label} placement='right'>
      <IconButton sx={{ color: isActive ? theme.palette.tertiary.main : theme.palette.common.white, opacity: !isActive ? 0.5 : 1 }}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}

export default function AppSidebar({ width }: { width: string }) {
  const theme = useTheme();
  const matchRoute = useMatchRoute()
  return (
    <Box width={width} position='fixed' height='100vh' top={0} left={0} zIndex={1} bgcolor={theme.palette.common.black}>
      <Stack paddingY={2} height='100%'>
        {/* Logo Section */}
        <MuiLink to='/linkedin/$tab' params={{ tab: LinkedInTabs.Overview }} style={{ height: 'auto', display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="RevDog logo" style={{ width: '55%', height: 'auto' }} />
        </MuiLink>

        {/* Main Menu Section */}
        <Stack gap={3} flex={1} marginTop={theme.spacing(6)}>
          <MuiLink to='/linkedin/$tab' params={{ tab: LinkedInTabs.Overview }} display='flex' justifyContent='center'>
            {() => {
              const isMatch = Boolean(matchRoute({ to: '/linkedin/$tab', fuzzy: true }))
              return (
                <SidebarItem icon={<LinkedInIcon />} label="LinkedIn" isActive={isMatch} />
              )
            }}
          </MuiLink>
          <MuiLink to='/users' display='flex' justifyContent='center'>
            {({ isActive }) => (
              <SidebarItem icon={<PeopleAltIcon />} label="Users" isActive={isActive} />
            )}
          </MuiLink>
        </Stack>

        {/* Other Section */}
        <Stack>
          <UserSettings />
        </Stack>
      </Stack>
    </Box>
  );

}
