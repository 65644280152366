import posthog from 'posthog-js'
import { MODE, POSTHOG_KEY } from '@utils/env.values'


if (MODE === 'production') {
  posthog.init(POSTHOG_KEY, {
    api_host: 'https://us.i.posthog.com',
    autocapture: false,
    enable_heatmaps: true,
  })
}


export const posthogClient = posthog
