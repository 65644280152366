import CommonDataGrid from "@components/CommonDataGrid";
import { Chip } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Activity', flex: 1 },
  {
    field: 'status', headerName: 'Status', width: 150,
    renderCell: (params) => {
      return <Chip size="small" variant="outlined" label={params.value} color={params.value === 'success' ? 'success' : 'error'} />
    },
  },
  { field: 'date', headerName: 'Date', width: 150, type: 'date' },
]

const rows: GridValidRowModel[] = Array.from({ length: 20 }).map((_, index) => ({
  id: index + 1,
  name: ['Connection request sent', 'Connection request accepted'][Math.floor(Math.random() * 2)],
  status: ['success', 'error'][Math.floor(Math.random() * 2)],
  date: new Date(),
}))

export default function LinkedInGrid() {
  return (
    <CommonDataGrid
      columns={columns}
      rows={rows}
      getRowId={(row) => row.id}
      disableRowSelectionOnClick
    />
  )
}
