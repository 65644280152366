import { createContext, useContext } from "react";
import { User } from "@apis/auth";

export type TAuthContext = {
  isAuthenticated: boolean;
  user: User | null;
  setUser: (user: User | null) => void;
}

const defaultAuthContext: TAuthContext = {
  isAuthenticated: false,
  user: null,
  setUser: () => {},
}

export const AuthContext = createContext<TAuthContext>(defaultAuthContext);

export const AuthContextProvider = AuthContext.Provider

export const useAuthContext = () => useContext(AuthContext);
