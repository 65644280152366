import http from "@apis/http";
import { queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_BASE_URL } from "@utils/env.values";

const integrationBaseUrl = `${API_BASE_URL}/integrations`

export enum IntegrationTypes {
  LINKEDIN = 'linkedin',
}

/**
 * Get Integration Status
 * Get the status of the integration for the current user
 */
type IntegrationMetaData<IntegrationTypes> = {
  created_at: string;
  id: string;
  integration: IntegrationTypes;
  shared: boolean;
  updated_at: string;
  user_id: string;
}

export type LinkedInIntegration = {
  is_connected: boolean;
  integration?: {
    account_id: string;
    account_name: string;
    provider_id: string;
  }
  metadata?: IntegrationMetaData<IntegrationTypes.LINKEDIN>
}


type GetIntegrationStatusResponse<T extends IntegrationTypes> = 
  T extends IntegrationTypes.LINKEDIN ? LinkedInIntegration :
  never;

const getIntegrationStatusQueryKey = <T extends IntegrationTypes>(integrationType: T) => ['integrations', integrationType, 'status']

const getIntegrationStatusQueryOptions = <T extends IntegrationTypes>(integrationType: T) => queryOptions<GetIntegrationStatusResponse<T>, Error>({
  queryKey: getIntegrationStatusQueryKey(integrationType),
  queryFn: () => http.get(`${integrationBaseUrl}/status?data_source_identifier=${integrationType}`),
})

export const linkedinStatusQueryOptions = getIntegrationStatusQueryOptions(IntegrationTypes.LINKEDIN)

export const useGetIntegrationStatus = <T extends IntegrationTypes>(integrationType: T) => {
  return useQuery<GetIntegrationStatusResponse<T>, Error>({
    ...getIntegrationStatusQueryOptions<T>(integrationType),
  });
}

/**
 * Get config options for a given integration
 */
type CommonIntegrationAuthResponse = {
  oauth_type: 'url_redirect',
  oauth_url: string,
}
type SalesforceIntegrationAuthArgs = CommonIntegrationAuthResponse & {
  return_params: [
    'code'
  ]
};
type LinkedInIntegrationAuthArgs = CommonIntegrationAuthResponse

type GetConnectionOAuthUrlResponse = SalesforceIntegrationAuthArgs | LinkedInIntegrationAuthArgs;

export function useIntegrationAuthMutation(integrationType: IntegrationTypes) {
  return useMutation<GetConnectionOAuthUrlResponse, Error>({
    mutationFn: () => http.get(`${integrationBaseUrl}/auth?data_source_identifier=${integrationType}`),
  });
}


/**
 * Delete an integration
 */
type DeleteIntegrationArgs = {
  integration_id: string;
}
type DeleteIntegrationResponse = {
  type: 'success'
}
export function useDeleteIntegrationMutation(integrationType: IntegrationTypes) {
  const queryClient = useQueryClient();
  return useMutation<DeleteIntegrationResponse, Error, DeleteIntegrationArgs>({
    mutationFn: ({ integration_id }) => http.delete(`${integrationBaseUrl}/${integration_id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getIntegrationStatusQueryKey(integrationType) });
    },
  });
}
