import * as Sentry from "@sentry/react";
import { Environment, SENTRY_DSN, SENTRY_ENVIRONMENT } from "@utils/env.values";

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: SENTRY_ENVIRONMENT !== Environment.LOCAL,
  environment: SENTRY_ENVIRONMENT,
  // Tracing
  tracesSampleRate: SENTRY_ENVIRONMENT === Environment.LOCAL || SENTRY_ENVIRONMENT === Environment.UAT ? 1.0 : 0.2,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/uat.rev-amp.ai\/api/, /^https:\/\/app.rev-amp.ai\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.05, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
