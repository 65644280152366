import { BackgroundOutlinePaper } from "@components/papers/BackgroundOutlinePaper";
import { styled } from "@mui/material";

const SignInButtonPaper = styled(BackgroundOutlinePaper)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  gap: theme.spacing(2),
  cursor: disabled ? 'not-allowed' : 'pointer',
  '&:hover': {
    boxShadow: theme.shadows[3],
  },
  opacity: disabled ? 0.5 : 1,
  pointerEvents: disabled ? 'none' : 'auto',
}))

export default SignInButtonPaper
