import http from "@apis/http";
import { queryOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { API_BASE_URL } from "@utils/env.values";

const usersBaseUrl = `${API_BASE_URL}/users`

// Get Tenant Users
export type TenantUser = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  is_admin: boolean;
}

type GetTenantUsersResponse = {
  users: TenantUser[];
}

export const getTenantUsersQueryOptions = queryOptions<GetTenantUsersResponse, Error>({
  queryKey: ['users'],
  queryFn: () => http.get(`${usersBaseUrl}/all`),
})

// Invite User
type InviteUserArgs = {
  email: string;
}

type InviteUserResponse = {
  message: string;
}

export function useInviteUserMutation() {
  const queryClient = useQueryClient();
  return useMutation<InviteUserResponse, Error, InviteUserArgs>({
    mutationFn: (args) => http.post(`${usersBaseUrl}/invite`, args),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    }
  })
}

// Patch User
type PatchUserArgs = {
  user_id: string;
  is_active: boolean;
}

type PatchUserResponse = {
  message: string;
}

export function usePatchUserMutation() {
  const queryClient = useQueryClient();
  return useMutation<PatchUserResponse, Error, PatchUserArgs>({
    mutationFn: (args) => http.patch(`${usersBaseUrl}/${args.user_id}`, { is_active: args.is_active }),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ['users'] });
    }
  })
}
