import { Paper, PaperProps, styled } from "@mui/material";
import { forwardRef } from 'react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  border: 'none',
  padding: theme.spacing(2),
}))


export const FlatPaper = forwardRef<HTMLDivElement, PaperProps>(function FlatPaper(props, ref) {
  const { children, ...rest } = props;
  return <StyledPaper {...rest} variant="outlined" ref={ref}>{children}</StyledPaper>;
});
