import logo from '@assets/co-pilot-logo.png'
import SignInWithSalesforce from '@components/auth/SignInWithSalesforce'
import RevDogDescription from '@components/auth/RevDogDescription'
import { MuiLink } from '@components/Link'
import { FlatPaper } from '@components/papers/FlatPaper'
import { Grid2, Stack, Typography } from '@mui/material'
import { createFileRoute, Outlet } from '@tanstack/react-router'
import { privacyPolicyUrl } from '@utils/constants'

export const Route = createFileRoute('/signin/')({
  component: RouteComponent,
})

function SignInCard() {
  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12} container justifyContent="center">
        <img src={logo} alt="RevDog Logo" width={70} />
      </Grid2>
      <Grid2 size={12}>
        <FlatPaper sx={{ paddingX: [2, 4, 6], paddingY: 4 }}>
          <Stack gap={2}>
            <Typography variant="h5" textAlign={'center'}>
              Sign in to RevDog
            </Typography>
            <SignInWithSalesforce />
            {/* <SignInWithHubSpot /> */}
          </Stack>
        </FlatPaper>
      </Grid2>
      <Grid2 size={12}>
        <Typography
          variant="caption"
          textAlign={'center'}
          component="p"
          color="text.secondary"
          marginTop={2}
        >
          By continuing, you agree to our{' '}
          <MuiLink to={privacyPolicyUrl as string} target="_blank">
            Privacy Policy.
          </MuiLink>
        </Typography>
      </Grid2>
    </Grid2>
  )
}

function RouteComponent() {
  return (
    <Grid2
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100vh' }}
      paddingX={4}
      spacing={6}
    >
      <Grid2 size={{ xs: 12, md: 6, lg: 5, xl: 4 }} order={[2, 2, 1]}>
        <RevDogDescription />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6, lg: 5, xl: 4 }} order={[1, 1, 2]}>
        <SignInCard />
      </Grid2>
    </Grid2>
  )
}
