import { Alert, AlertTitle, useTheme } from "@mui/material";
import { DataGrid, DataGridProps, GRID_CHECKBOX_SELECTION_FIELD, GridOverlay, GridValidRowModel, gridClasses } from "@mui/x-data-grid";
import { useMemo } from "react";


function NoRowsOverlay() {
  return (
    <GridOverlay>
      <Alert severity="info">
        <AlertTitle>No rows available</AlertTitle>
      </Alert>
    </GridOverlay>
  )
}


export default function CommonDataGrid<T extends GridValidRowModel>(props: DataGridProps<T> & { height?: string }) {
  const theme = useTheme();
  const {
    slots,
    disableColumnFilter = true,
    disableColumnSorting = false,
    height = '80vh',
    slotProps,
    ...rest
  } = props;

  const isEditable = useMemo(() => {
    return props.processRowUpdate !== undefined || props.onCellEditStop !== undefined
  }, [props.processRowUpdate, props.onCellEditStop])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height }}>
      <DataGrid<T>
        disableColumnFilter={disableColumnFilter}
        disableColumnSorting={disableColumnSorting}
        sx={{
          [`& .${gridClasses.columnHeader}`]: {
            backgroundColor: theme.palette.background.paper,
          },
          // Remove outline from column header
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
          {
            outline: 'none',
          },
          ...(!isEditable && {
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none',
            },
          }),
        }}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          ...slots
        }}
        // Hide text from the toolbar buttons
        localeText={{
          toolbarColumns: '',
          toolbarExport: '',
        }}
        slotProps={{
          ...slotProps,
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
            ...slotProps?.loadingOverlay,
          },
          columnsManagement: {
            ...slotProps?.columnsManagement,
            getTogglableColumns: (columns) => {
              if (slotProps?.columnsManagement?.getTogglableColumns) {
                return slotProps.columnsManagement.getTogglableColumns(columns)
              }
              return columns.filter((col) => col.field !== GRID_CHECKBOX_SELECTION_FIELD).map((col) => col.field)
            },
          },
        }}
        {...rest}
      />
    </div>
  )
}
