import { FlatPaper } from "@components/papers/FlatPaper";
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';
import TrendingDownOutlinedIcon from '@mui/icons-material/TrendingDownOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import { SvgIconComponent } from "@mui/icons-material";
import { Chip, Grid2, Stack, Typography } from "@mui/material";

type ItemProps = {
  label: string
  value: number | string
  Icon: SvgIconComponent
  trend?: number
}



function Item({ label, value, Icon, trend }: ItemProps) {

  return (
    <FlatPaper>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant="caption" color="text.secondary">{label}</Typography>
        {trend && <Chip size="small" variant="outlined" label={`${trend}%`} color={trend > 0 ? 'success' : 'error'} icon={trend > 0 ? <TrendingUpOutlinedIcon /> : <TrendingDownOutlinedIcon />} />}
      </Stack>
      <Typography variant="body1" lineHeight={1.2} fontWeight='medium' fontSize={40} textAlign='left'>{value}</Typography>
      <Typography variant="caption" color="text.secondary" textAlign='left'>Last 30 days</Typography>
    </FlatPaper>
  )
}


export default function LinkedInSection({ showTitle = true }: { showTitle?: boolean }) {
  return (
    <Grid2 container spacing={3}>
      {
        showTitle && (
          <Grid2 size={12}>
            <Typography variant='h3'>LinkedIn</Typography>
          </Grid2>
        )
      }
      <Grid2 size={{ xs: 12, md: 6, lg: 3 }}>
        <Item label="Sent requests" value={12} Icon={UpgradeOutlinedIcon} trend={10} />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6, lg: 3 }}>
        <Item label="Pending requests" value={6} Icon={UpgradeOutlinedIcon} />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6, lg: 3 }}>
        <Item label="Accepted requests" value={4} Icon={UpgradeOutlinedIcon} trend={-2} />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6, lg: 3 }}>
        <Item label="Failed requests" value={2} Icon={UpgradeOutlinedIcon} />
      </Grid2>
    </Grid2>
  )
}
