import AppAlerts from '@components/AppAlerts';
import { useAuthContext } from '@components/auth/auth.ctx';
import { AuthProvider } from '@components/auth/AuthProvider';
import ErrorBoundary from '@components/ErrorBoundary';
import PageSpinner from '@components/PageSpinner';
import { posthogClient } from '@instrumentation/posthog';
import '@instrumentation/sentry';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { PostHogProvider } from 'posthog-js/react';
import { routeTree } from './routeTree.gen';
import { lightTheme } from './theme';

const queryClient = new QueryClient();

const router = createRouter({
  routeTree,
  context: {
    queryClient,
    auth: undefined!
  },
  defaultPendingComponent: () => <PageSpinner />,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

function RouterWrapper() {
  const auth = useAuthContext();
  return <RouterProvider router={router} context={{ queryClient, auth }} />
}

function App() {
  return (
    <PostHogProvider client={posthogClient}>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline enableColorScheme />
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <AppAlerts>
              <AuthProvider>
                <RouterWrapper />
              </AuthProvider>
            </AppAlerts>
          </ErrorBoundary>
        </QueryClientProvider>
      </ThemeProvider>
    </PostHogProvider>
  )
}

export default App
