import { Paper, PaperProps, styled } from "@mui/material";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}))

export function BackgroundOutlinePaper(props: PaperProps) {
  const { children, ...rest } = props
  return <StyledPaper {...rest} variant="outlined">{children}</StyledPaper>
}
