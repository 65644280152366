import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ArrowBackIosNewRounded from '@mui/icons-material/ArrowBackIosNewRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ErrorScreenImage from '@assets/custom-svg/ErrorScreenImage';
import { helpEmail } from '@utils/constants';


interface ErrorEmptyStateProps {
  handleOnClick: () => void;
}

export default function ErrorEmptyState({ handleOnClick }: ErrorEmptyStateProps) {
  // const navigate = useNavigate();
  const handleNeedhelp = () => {
    const mailtoLink = `mailto:${helpEmail}`;
    window.open(mailtoLink, '_blank');
  };

  const handleClick = () => {
    if (handleOnClick) {
      handleOnClick();
    }
    // navigate(rootPath);
  }
  return (
    <Grid
      container
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      gap={7}
      sx={{ verticalAlign: 'middle', height: '100%' }}
    >
      <Grid item>
        <ErrorScreenImage style={{ verticalAlign: 'middle' }} />
      </Grid>
      <Grid item container direction='column' gap={5}>
        <Grid container item direction='column' gap={1}>
          <Typography variant='h6'>Something went wrong</Typography>
        </Grid>
        <Grid item container gap={3} justifyContent='center'>
          <Button
            variant='outlined'
            startIcon={<ArrowBackIosNewRounded />}
            onClick={handleClick}
          >
            Go Home
          </Button>
          <Button
            variant='contained'
            onClick={handleNeedhelp}
            startIcon={<SupportAgentRoundedIcon />}
          >
            Need help?
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
