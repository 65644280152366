import { linkedinStatusQueryOptions } from '@apis/integration'
import { PromptConnectLinkedIn } from '@components/linkedin/PromptConnectLinkedIn'
import StopIcon from '@mui/icons-material/Stop'
import { Box, Button, Grid2, keyframes, Stack, styled, Typography } from '@mui/material'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Outlet } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_authenticated/_applayout/(linkedin)/linkedin',
)({
  component: RouteComponent,
  beforeLoad: ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(linkedinStatusQueryOptions)
  }
})


const pulseRing = keyframes`
  0% { transform: scale(.5); }
  80%, 100% { opacity: 0; }
`

const pulseDot = keyframes`
 0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
`

const PulseBox = styled(Box)(({ theme }) => ({
  animation: `${pulseDot} 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite`,
  backgroundColor: theme.palette.tertiary.main,
  borderRadius: '50%',
  boxSizing: 'border-box',
  height: '10px',
  width: '10px',
  '&:before': {
    animation: `${pulseRing} 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite`,
    backgroundColor: theme.palette.tertiary.main,
    borderRadius: '45px',
    content: '""',
    display: 'block',
    height: '300%',
    left: '-100%',
    position: 'relative',
    top: '-100%',
    width: '300%',
  }
}))



function RouteComponent() {
  const { data } = useSuspenseQuery(linkedinStatusQueryOptions)
  
  if (!data?.is_connected) {
    return <PromptConnectLinkedIn />
  }

  return (
    <Grid2 container spacing={3}>
      <Grid2
        size={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h1">LinkedIn</Typography>
          <PulseBox />
        </Stack>
        <Box flex={1} />
        <Button variant="contained" startIcon={<StopIcon />}>
          Stop
        </Button>
      </Grid2>
      <Grid2 size={12}>
        <Outlet />
      </Grid2>
    </Grid2>
  )
}
