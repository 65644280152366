import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogProps, DialogTitle, IconButton } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  heading?: ReactNode;
} & DialogProps;

export default function CommonDialog(props: Props) {
  const {
    heading = '',
    maxWidth = 'sm',
    fullWidth = true,
    onClose,
    children,
    ...other
  } = props;
  return (
    <Dialog {...other} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogTitle>{heading}</DialogTitle>
      <IconButton
        onClick={(e) => onClose?.(e, 'backdropClick')}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </Dialog>
  )
}
