import { User } from '@apis/auth';
import { AuthContextProvider, TAuthContext } from '@components/auth/auth.ctx';
import { useMemo, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state'


export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useLocalStorageState<User | null>('user', {
    defaultValue: null,
  });
  

  const authCtx: TAuthContext = useMemo(() => ({
    isAuthenticated: !!user,
    user,
    setUser,
  }), [user]);


  return (
    <AuthContextProvider value={authCtx}>
      {children}
    </AuthContextProvider>
  );
}
