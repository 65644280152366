import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Divider, FormControlLabel, Slider, Stack, Typography, styled } from "@mui/material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FlatPaper } from "@components/papers/FlatPaper";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "transparent",
}))

const MAX = 30;
const MIN = 0;

function LinkedInSettings() {
  return (
    <FlatPaper>
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
        <LinkedInIcon color="action" />
        <Typography variant="body1">LinkedIn</Typography>
        <Box flex={1} />
        <FormControlLabel control={<Checkbox defaultChecked size="small" color="secondary" />} label="Active" labelPlacement="end" sx={{ fontSize: "0.8rem" }} />
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} sx={{ padding: 2 }}>
        <Typography variant="body2" flex={1}>Number of connections to be sent per day</Typography>
        <Slider
          min={MIN} max={MAX}
          step={1} size="medium" sx={{ maxWidth: 150 }}
          defaultValue={25} color="secondary"
          valueLabelDisplay="auto"
        />
      </Stack>
    </FlatPaper>
  )
}

export default function UserSettingsAccordion() {
  return (
    <StyledAccordion disableGutters>
      <AccordionSummary sx={{ padding: 0, width: 150, minHeight: 35, '& .MuiAccordionSummary-content': { margin: 0 } }}>
        <Stack direction="row" alignItems="center" color="text.secondary">
          <Typography variant="caption">More Settings</Typography>
          <ArrowDropDownIcon />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <LinkedInSettings />
        <Stack direction="row" justifyContent="flex-end" marginTop={2}>
          <Button variant="outlined" size="small">Save</Button>
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  )
}
