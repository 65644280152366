import AppSidebar from '@components/AppSidebar'
import { Box } from '@mui/material'
import { createFileRoute, Outlet } from '@tanstack/react-router'
import { Fragment } from 'react'

export const Route = createFileRoute('/_authenticated/_applayout')({
  component: RouteComponent,
})

const sidebarWidth = '50px'

function RouteComponent() {

  return (
    <Fragment>
      <AppSidebar width={sidebarWidth} />
      <Box marginLeft={sidebarWidth} paddingX={4} paddingY={2}>
        <Outlet />
      </Box>
    </Fragment>
  )
}
