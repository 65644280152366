import { createTheme, ThemeOptions, inputBaseClasses } from "@mui/material";

declare module '@mui/material/styles' {

  interface Palette {
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
  }

  interface Theme {
    custom: {
      // Sample
      // feedbackIconSize: number;
    },
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      // feedbackIconSize?: number;
    }
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
  interface ButtonPropsSizeOverrides {
    xl: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    xsm: true;
  }
}

const colors = {
  peachPuff: '#ffcd9e',
  brightGreen: '#aee64b',
  lavender: '#d6e1ff',
  purple: '#d0a8f7',
  whiteSmoke: '#efefef',
  darkGreen: '#254e34',
  aquamarine: '#a8fbd3',
  whiteSmoke2: '#eff3f6',
  linen: '#f7f6f0',
  linenDark: '#efede2',
  black: '#000000',
  white: '#ffffff',
  instantlyPaper: '#f9f9f9'
}


const commonThemeValues: ThemeOptions = {
  custom: {
    // Sample
    // feedbackIconSize: 18,
  },
  shape: {
    borderRadius: 6,
  }
}

const defaultTheme = createTheme(commonThemeValues);

export const lightTheme = createTheme({
  ...commonThemeValues,
  typography: {
    fontFamily: 'Poppins, sans-serif',
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.15rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.10rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: colors.black,
    },
    secondary: {
      main: colors.darkGreen,
    },
    tertiary: {
      main: colors.brightGreen,
      contrastText: colors.black,
    },
    success: {
      main: colors.darkGreen,
    },
    info: {
      main: colors.purple,
    },
    background: {
      default: colors.white,
      paper: colors.instantlyPaper,
    },
    text: {
      primary: colors.black,
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.black,
          color: colors.white
        },
        arrow: {
          color: colors.black
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.white,
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(2),
          '&:last-child': {
            paddingBottom: defaultTheme.spacing(2),
          }
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { size: 'xl' },
          style: {
            paddingTop: defaultTheme.spacing(2),
            paddingBottom: defaultTheme.spacing(2),
          }
        }
      ]
    },
  },
});
