import { TenantUser, usePatchUserMutation } from "@apis/users"
import { useAppAlerts } from "@components/AppAlerts"
import { useAuthContext } from "@components/auth/auth.ctx"
import ComponentSpinner from "@components/ComponentSpinner"
import { BackgroundOutlinePaper } from "@components/papers/BackgroundOutlinePaper"
import UserAvatar from "@components/UserAvatar"
import UserSettingsAccordion from "@components/users/UserSettingsAccordion"
import { Box, Chip, Stack, Switch, Tooltip, Typography } from "@mui/material"


function UserListItem({ user }: { user: TenantUser }) {
  const { addAlert } = useAppAlerts()
  const { user: currentUser } = useAuthContext()
  const { mutateAsync: patchUser, isPending: isPatchingUser } = usePatchUserMutation()

  const handleToggleUserActive = async () => {
    try {
      await patchUser({ user_id: user.id, is_active: !user.is_active })
      addAlert({ type: "success", message: `${user.is_active ? "Disabled" : "Enabled"} user ${user.first_name} ${user.last_name}` })
    } catch (error) {
      addAlert({ type: "error", message: "Failed to toggle user's active status" })
    }
  }


  return (
    <BackgroundOutlinePaper sx={{ padding: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
        <Box alignSelf="flex-start" marginTop={0.5}>
          <UserAvatar name={user.first_name} />
        </Box>
        <Stack flex={1}>
          <Typography variant="subtitle2">{user.first_name} {user.last_name}
            {user.is_admin && <Chip variant="outlined" size="small" label="Admin" color="secondary" sx={{ ml: 1, fontSize: "0.70rem" }} />}
          </Typography>
          <Typography variant="caption" color="text.secondary">{user.email}</Typography>
          <UserSettingsAccordion />
        </Stack>
        <Box alignSelf="flex-start" visibility={currentUser?.id === user.id ? "hidden" : "visible"} sx={{ pointerEvents: currentUser?.id === user.id ? "none" : "auto" }}>
          <Tooltip title="Enable/Disable a User">
            {isPatchingUser ? <ComponentSpinner size={18} /> : (
              <Switch checked={user.is_active} onChange={handleToggleUserActive} disabled={currentUser?.is_admin !== true} />
            )}
          </Tooltip>
        </Box>
      </Stack>

    </BackgroundOutlinePaper>
  )
}


type UserListProps = {
  users: TenantUser[]
}

export default function UserList({ users }: UserListProps) {

  return (
    <Stack gap={2}>
      {users.map((user) => (
        <UserListItem key={user.id} user={user} />
      ))}
    </Stack>
  )
}
