import { Avatar, useTheme } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

const size = 32;

export default function UserAvatar({ name }: { name: string }) {
  const theme = useTheme();
  return (
    <Avatar variant="rounded" sx={{ width: size, height: size, backgroundColor: theme.palette.grey[600] }}>
      {name[0].toUpperCase() || <PersonIcon />}
    </Avatar>
  )
}
