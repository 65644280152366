import { getLoggedInUserQueryOptions } from '@apis/auth';
import { useAppAlerts } from '@components/AppAlerts';
import { useAuthContext } from '@components/auth/auth.ctx';
import { useQueryErrorResetBoundary, useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, Navigate, Outlet, redirect, useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';

export const Route = createFileRoute('/_authenticated')({
  async beforeLoad({ context: { auth } }) {
    if (!auth.isAuthenticated) {
      throw redirect({ to: '/signin' });
    }
  },
  loader: async ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(getLoggedInUserQueryOptions)
  },
  errorComponent: ({ error, reset }) => {
    const { addAlert } = useAppAlerts()
    const { setUser } = useAuthContext()
    const router = useRouter()
    const queryErrorResetBoundary = useQueryErrorResetBoundary()

    useEffect(() => {
      // Reset the query error boundary
      queryErrorResetBoundary.reset()
      setUser(null)
      addAlert({
        message: error.message,
        type: 'error',
      })
      router.invalidate()
    }, [queryErrorResetBoundary])

    return <Navigate to="/signin" />
  },
  component: () => {
    const { user, setUser } = useAuthContext()
    const { data } = useSuspenseQuery(getLoggedInUserQueryOptions)

    useEffect(() => {
      if (JSON.stringify(user) !== JSON.stringify(data)) {
        setUser(data)
      }
    }, [data])

    // if the user is not active, redirect to the signin page
    if (!data.is_active) {
      return <Navigate to="/signin" />
    }

    return <Outlet />
  },
})
