import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { forwardRef } from "react";


type Props = ButtonProps & {
  isLoading: boolean;
};

type Sizes = ButtonProps['size'];

const SizeMap: { [key in Sizes as string]: number } = {
  small: 18,
  medium: 22,
  large: 26,
  xl: 26,
};

const LoadingButton = forwardRef<HTMLButtonElement, Props>(({ isLoading, ...props }, ref) => {
  const disabled = isLoading || props.disabled;
  return (
    <Button {...props} startIcon={isLoading ? <CircularProgress size={SizeMap[props.size || 'medium']} color='inherit' /> : props.startIcon} ref={ref} disabled={disabled}>
      {props.children}
    </Button>
  );
});

LoadingButton.displayName = "LoadingButton";

export default LoadingButton;
