import * as React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { Grid2, Stack, Typography } from '@mui/material'
import { getTenantUsersQueryOptions } from '@apis/users'
import { useSuspenseQuery } from '@tanstack/react-query'
import InviteUserBtn from '@components/users/InviteUserBtn'
import UserList from '@components/users/UserList'

export const Route = createFileRoute('/_authenticated/_applayout/users')({
  loader: ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(getTenantUsersQueryOptions)
  },
  component: RouteComponent,
})

function RouteComponent() {
  const { data } = useSuspenseQuery(getTenantUsersQueryOptions)

  return (
    <Grid2 container spacing={3}>
      <Grid2
        size={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <Typography variant="h1">Users</Typography>
          <InviteUserBtn />
        </Stack>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 8 }} offset={{ md: 2 }}>
        <UserList users={data.users} />
      </Grid2>
    </Grid2>
  )
}
