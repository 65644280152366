import { useAuthContext } from '@components/auth/auth.ctx';
import { MenuItemLink } from '@components/MenuItemLink';
import UserAvatar from '@components/UserAvatar';
import { AccountBoxOutlined, CableOutlined, Logout } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
// import { useHubSpotChat } from '@components/hubspot/useHubSpotChat';
// import { NestedMenuItem } from 'mui-nested-menu';
import { useState } from 'react';


export default function UserSettings() {
  const { user } = useAuthContext();
  const [mainAnchorEl, setMainAnchorEl] = useState<null | HTMLElement>(null);
  const isMainMenuOpen = Boolean(mainAnchorEl);

  // const { isReady, openHandler } = useHubSpotChat()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setMainAnchorEl(event.currentTarget);
  };

  const handleMainMenuClose = () => {
    setMainAnchorEl(null);
  };

  if (!user) {
    return null;
  }


  return (
    <>
      <IconButton onClick={handleClick} size='large'>
        <UserAvatar name={user.first_name} />
      </IconButton>
      <Menu
        anchorEl={mainAnchorEl}
        open={isMainMenuOpen}
        onClose={handleMainMenuClose}
        MenuListProps={{ dense: true, sx: { minWidth: 200 } }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem sx={{ pointerEvents: 'none' }}>
          <ListItemIcon>
            <AccountBoxOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.organization.organization_name} />
        </MenuItem>
        {/* <NestedMenuItem
          parentMenuOpen={isMainMenuOpen}
          leftIcon={(
            <ListItemIcon sx={{ marginLeft: 1.5 }}>
              <AccountBoxOutlined fontSize="small" />
            </ListItemIcon>
          )}
          disabled={true}
          rightIcon={null}
          sx={{ opacity: '1 !important' }}
          renderLabel={() => (
            <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.organization.organization_name} />
          )}>
        </NestedMenuItem> */}

        <Divider />

        <MenuItemLink to='/settings' onClick={handleMainMenuClose}>
          <ListItemIcon>
            <CableOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Settings
          </ListItemText>
        </MenuItemLink>

        {/* <MenuItem onClick={() => { openHandler(); handleMainMenuClose() }} disabled={!isReady}>
          <ListItemIcon>
            <HelpCenterOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Support
          </ListItemText>
        </MenuItem> */}

        {/* <Divider sx={{ margin: `0 !important` }} /> */}
        <MenuItemLink to='/signout' onClick={handleMainMenuClose} preload={false}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Logout
          </ListItemText>
        </MenuItemLink>
      </Menu>
    </>
  )
}
