/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SignoutImport } from './routes/signout'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'
import { Route as SigninIndexImport } from './routes/signin.index'
import { Route as SigninSalesforceImport } from './routes/signin.salesforce'
import { Route as AuthenticatedApplayoutImport } from './routes/_authenticated/_applayout'
import { Route as AuthenticatedApplayoutUsersImport } from './routes/_authenticated/_applayout/users'
import { Route as AuthenticatedApplayoutlinkedinLinkedinImport } from './routes/_authenticated/_applayout/(linkedin)/linkedin'
import { Route as AuthenticatedApplayoutlinkedinLinkedinTabImport } from './routes/_authenticated/_applayout/(linkedin)/linkedin.$tab'

// Create Virtual Routes

const AuthenticatedApplayoutSettingsLazyImport = createFileRoute(
  '/_authenticated/_applayout/settings',
)()

// Create/Update Routes

const SignoutRoute = SignoutImport.update({
  id: '/signout',
  path: '/signout',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const SigninIndexRoute = SigninIndexImport.update({
  id: '/signin/',
  path: '/signin/',
  getParentRoute: () => rootRoute,
} as any)

const SigninSalesforceRoute = SigninSalesforceImport.update({
  id: '/signin/salesforce',
  path: '/signin/salesforce',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedApplayoutRoute = AuthenticatedApplayoutImport.update({
  id: '/_applayout',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedApplayoutSettingsLazyRoute =
  AuthenticatedApplayoutSettingsLazyImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => AuthenticatedApplayoutRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_applayout/settings.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedApplayoutUsersRoute =
  AuthenticatedApplayoutUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => AuthenticatedApplayoutRoute,
  } as any)

const AuthenticatedApplayoutlinkedinLinkedinRoute =
  AuthenticatedApplayoutlinkedinLinkedinImport.update({
    id: '/(linkedin)/linkedin',
    path: '/linkedin',
    getParentRoute: () => AuthenticatedApplayoutRoute,
  } as any)

const AuthenticatedApplayoutlinkedinLinkedinTabRoute =
  AuthenticatedApplayoutlinkedinLinkedinTabImport.update({
    id: '/$tab',
    path: '/$tab',
    getParentRoute: () => AuthenticatedApplayoutlinkedinLinkedinRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/signout': {
      id: '/signout'
      path: '/signout'
      fullPath: '/signout'
      preLoaderRoute: typeof SignoutImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_applayout': {
      id: '/_authenticated/_applayout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedApplayoutImport
      parentRoute: typeof AuthenticatedImport
    }
    '/signin/salesforce': {
      id: '/signin/salesforce'
      path: '/signin/salesforce'
      fullPath: '/signin/salesforce'
      preLoaderRoute: typeof SigninSalesforceImport
      parentRoute: typeof rootRoute
    }
    '/signin/': {
      id: '/signin/'
      path: '/signin'
      fullPath: '/signin'
      preLoaderRoute: typeof SigninIndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_applayout/users': {
      id: '/_authenticated/_applayout/users'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof AuthenticatedApplayoutUsersImport
      parentRoute: typeof AuthenticatedApplayoutImport
    }
    '/_authenticated/_applayout/settings': {
      id: '/_authenticated/_applayout/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthenticatedApplayoutSettingsLazyImport
      parentRoute: typeof AuthenticatedApplayoutImport
    }
    '/_authenticated/_applayout/(linkedin)/linkedin': {
      id: '/_authenticated/_applayout/(linkedin)/linkedin'
      path: '/linkedin'
      fullPath: '/linkedin'
      preLoaderRoute: typeof AuthenticatedApplayoutlinkedinLinkedinImport
      parentRoute: typeof AuthenticatedApplayoutImport
    }
    '/_authenticated/_applayout/(linkedin)/linkedin/$tab': {
      id: '/_authenticated/_applayout/(linkedin)/linkedin/$tab'
      path: '/$tab'
      fullPath: '/linkedin/$tab'
      preLoaderRoute: typeof AuthenticatedApplayoutlinkedinLinkedinTabImport
      parentRoute: typeof AuthenticatedApplayoutlinkedinLinkedinImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedApplayoutlinkedinLinkedinRouteChildren {
  AuthenticatedApplayoutlinkedinLinkedinTabRoute: typeof AuthenticatedApplayoutlinkedinLinkedinTabRoute
}

const AuthenticatedApplayoutlinkedinLinkedinRouteChildren: AuthenticatedApplayoutlinkedinLinkedinRouteChildren =
  {
    AuthenticatedApplayoutlinkedinLinkedinTabRoute:
      AuthenticatedApplayoutlinkedinLinkedinTabRoute,
  }

const AuthenticatedApplayoutlinkedinLinkedinRouteWithChildren =
  AuthenticatedApplayoutlinkedinLinkedinRoute._addFileChildren(
    AuthenticatedApplayoutlinkedinLinkedinRouteChildren,
  )

interface AuthenticatedApplayoutRouteChildren {
  AuthenticatedApplayoutUsersRoute: typeof AuthenticatedApplayoutUsersRoute
  AuthenticatedApplayoutSettingsLazyRoute: typeof AuthenticatedApplayoutSettingsLazyRoute
  AuthenticatedApplayoutlinkedinLinkedinRoute: typeof AuthenticatedApplayoutlinkedinLinkedinRouteWithChildren
}

const AuthenticatedApplayoutRouteChildren: AuthenticatedApplayoutRouteChildren =
  {
    AuthenticatedApplayoutUsersRoute: AuthenticatedApplayoutUsersRoute,
    AuthenticatedApplayoutSettingsLazyRoute:
      AuthenticatedApplayoutSettingsLazyRoute,
    AuthenticatedApplayoutlinkedinLinkedinRoute:
      AuthenticatedApplayoutlinkedinLinkedinRouteWithChildren,
  }

const AuthenticatedApplayoutRouteWithChildren =
  AuthenticatedApplayoutRoute._addFileChildren(
    AuthenticatedApplayoutRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedApplayoutRoute: typeof AuthenticatedApplayoutRouteWithChildren
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedApplayoutRoute: AuthenticatedApplayoutRouteWithChildren,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedApplayoutRouteWithChildren
  '/signout': typeof SignoutRoute
  '/signin/salesforce': typeof SigninSalesforceRoute
  '/signin': typeof SigninIndexRoute
  '/users': typeof AuthenticatedApplayoutUsersRoute
  '/settings': typeof AuthenticatedApplayoutSettingsLazyRoute
  '/linkedin': typeof AuthenticatedApplayoutlinkedinLinkedinRouteWithChildren
  '/linkedin/$tab': typeof AuthenticatedApplayoutlinkedinLinkedinTabRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedApplayoutRouteWithChildren
  '/signout': typeof SignoutRoute
  '/signin/salesforce': typeof SigninSalesforceRoute
  '/signin': typeof SigninIndexRoute
  '/users': typeof AuthenticatedApplayoutUsersRoute
  '/settings': typeof AuthenticatedApplayoutSettingsLazyRoute
  '/linkedin': typeof AuthenticatedApplayoutlinkedinLinkedinRouteWithChildren
  '/linkedin/$tab': typeof AuthenticatedApplayoutlinkedinLinkedinTabRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/signout': typeof SignoutRoute
  '/_authenticated/_applayout': typeof AuthenticatedApplayoutRouteWithChildren
  '/signin/salesforce': typeof SigninSalesforceRoute
  '/signin/': typeof SigninIndexRoute
  '/_authenticated/_applayout/users': typeof AuthenticatedApplayoutUsersRoute
  '/_authenticated/_applayout/settings': typeof AuthenticatedApplayoutSettingsLazyRoute
  '/_authenticated/_applayout/(linkedin)/linkedin': typeof AuthenticatedApplayoutlinkedinLinkedinRouteWithChildren
  '/_authenticated/_applayout/(linkedin)/linkedin/$tab': typeof AuthenticatedApplayoutlinkedinLinkedinTabRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/signout'
    | '/signin/salesforce'
    | '/signin'
    | '/users'
    | '/settings'
    | '/linkedin'
    | '/linkedin/$tab'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/signout'
    | '/signin/salesforce'
    | '/signin'
    | '/users'
    | '/settings'
    | '/linkedin'
    | '/linkedin/$tab'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/signout'
    | '/_authenticated/_applayout'
    | '/signin/salesforce'
    | '/signin/'
    | '/_authenticated/_applayout/users'
    | '/_authenticated/_applayout/settings'
    | '/_authenticated/_applayout/(linkedin)/linkedin'
    | '/_authenticated/_applayout/(linkedin)/linkedin/$tab'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  SignoutRoute: typeof SignoutRoute
  SigninSalesforceRoute: typeof SigninSalesforceRoute
  SigninIndexRoute: typeof SigninIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  SignoutRoute: SignoutRoute,
  SigninSalesforceRoute: SigninSalesforceRoute,
  SigninIndexRoute: SigninIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/signout",
        "/signin/salesforce",
        "/signin/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/_applayout"
      ]
    },
    "/signout": {
      "filePath": "signout.tsx"
    },
    "/_authenticated/_applayout": {
      "filePath": "_authenticated/_applayout.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_applayout/users",
        "/_authenticated/_applayout/settings",
        "/_authenticated/_applayout/(linkedin)/linkedin"
      ]
    },
    "/signin/salesforce": {
      "filePath": "signin.salesforce.tsx"
    },
    "/signin/": {
      "filePath": "signin.index.tsx"
    },
    "/_authenticated/_applayout/users": {
      "filePath": "_authenticated/_applayout/users.tsx",
      "parent": "/_authenticated/_applayout"
    },
    "/_authenticated/_applayout/settings": {
      "filePath": "_authenticated/_applayout/settings.lazy.tsx",
      "parent": "/_authenticated/_applayout"
    },
    "/_authenticated/_applayout/(linkedin)/linkedin": {
      "filePath": "_authenticated/_applayout/(linkedin)/linkedin.tsx",
      "parent": "/_authenticated/_applayout",
      "children": [
        "/_authenticated/_applayout/(linkedin)/linkedin/$tab"
      ]
    },
    "/_authenticated/_applayout/(linkedin)/linkedin/$tab": {
      "filePath": "_authenticated/_applayout/(linkedin)/linkedin.$tab.tsx",
      "parent": "/_authenticated/_applayout/(linkedin)/linkedin"
    }
  }
}
ROUTE_MANIFEST_END */
