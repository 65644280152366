import * as React from 'react'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { Grid2, Tab, Tabs } from '@mui/material'
import LinkedInSection from '@components/linkedin/LinkedInSection'
import LinkedInGrid from '@components/linkedin/LinkedInGrid'
import LinkedInUsage from '@components/linkedin/LinkedInUsage'
import { LinkedInTabs } from '@components/linkedin/utils'



export const Route = createFileRoute(
  '/_authenticated/_applayout/(linkedin)/linkedin/$tab',
)({
  component: RouteComponent,
  beforeLoad: ({ params }) => {
    if (params?.tab === undefined || !Object.values(LinkedInTabs).includes(params.tab as LinkedInTabs)) {
      throw redirect({ to: '/linkedin/$tab', params: { tab: LinkedInTabs.Overview } })
    }
  }
})

function RouteComponent() {
  const { tab } = Route.useParams()
  const navigate = Route.useNavigate()
  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Tabs value={tab} onChange={(_, value) => {
          navigate({ to: '/linkedin/$tab', params: { tab: value } })
        }}>
          <Tab value={LinkedInTabs.Overview} label="Overview" />
          <Tab value={LinkedInTabs.Activity} label="Activity" />
          <Tab value={LinkedInTabs.Usage} label="Usage" />
        </Tabs>
      </Grid2>
      <Grid2 size={12}>
        {tab === LinkedInTabs.Overview && <LinkedInSection showTitle={false} />}
        {tab === LinkedInTabs.Activity && <LinkedInGrid />}
        {tab === LinkedInTabs.Usage && <LinkedInUsage />}
      </Grid2>
    </Grid2>
  )
}
