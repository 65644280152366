import { logoutUserQueryOptions } from '@apis/auth'
import { useAuthContext } from '@components/auth/auth.ctx'
import ComponentSpinner from '@components/ComponentSpinner'
import { EEvent, useTrackEvents } from '@instrumentation/useTrackEvents'
import { Container, Typography } from '@mui/material'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Navigate } from '@tanstack/react-router'
import { useEffect } from 'react'

export const Route = createFileRoute('/signout')({
  loader: ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(logoutUserQueryOptions)
  },
  pendingComponent: () => (
    <Container sx={{ marginTop: 4 }}>
      <ComponentSpinner />
      <Typography variant="h6" textAlign="center" color='textSecondary' sx={{ marginTop: 2 }}>Please wait while we log you out...</Typography>
    </Container>
  ),
  component: RouteComponent,
})

function RouteComponent() {
  const { setUser, user } = useAuthContext()
  const { data } = useSuspenseQuery(logoutUserQueryOptions)
  const { trackEvent } = useTrackEvents()

  useEffect(() => {
    if (data.logged_out) {
      const userProperties = { email: user?.email, first_name: user?.first_name, last_name: user?.last_name, org_name: user?.organization.organization_name }
      trackEvent(EEvent.USER_SIGNED_OUT, userProperties)
      setUser(null)
    }
  }, [data.logged_out])

  if (data.logged_out) {
    return <Navigate to="/signin" replace={true} />
  }

  return null
}
