import { createFileRoute, Navigate } from '@tanstack/react-router'
import { LinkedInTabs } from '@components/linkedin/utils'

export const Route = createFileRoute('/')({
  component: Index,

})

function Index() {
  return <Navigate to='/linkedin/$tab' params={{ tab: LinkedInTabs.Overview }} replace={true} />
}
