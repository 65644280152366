import { MenuItem, MenuItemProps } from '@mui/material'
import { createLink, LinkComponent } from '@tanstack/react-router'
import * as React from 'react'

interface MUILinkProps extends Omit<MenuItemProps, 'href'> {
  // Add any additional props you want to pass to the button
}

const MUILinkComponent = React.forwardRef<HTMLLIElement, MUILinkProps>(
  (props, ref) => {
    return <MenuItem ref={ref} {...props} />
  },
)

const CreatedLinkComponent = createLink(MUILinkComponent)

export const MenuItemLink: LinkComponent<typeof MUILinkComponent> = (props) => {
  return <CreatedLinkComponent preload={'intent'} {...props} />
}
