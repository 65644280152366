import { IntegrationTypes, useIntegrationAuthMutation } from "@apis/integration";
import { useAppAlerts } from "@components/AppAlerts";
import LoadingButton from "@components/LoadingButton";
import { FlatPaper } from "@components/papers/FlatPaper";
import { EEvent, useTrackEvents } from "@instrumentation/useTrackEvents";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Grid2, Typography } from "@mui/material";
import { useState } from "react";

export function PromptConnectLinkedIn() {
  const [iframeUrl, setIframeUrl] = useState<string | null>(null)
  const { addAlert } = useAppAlerts();
  const { trackEvent } = useTrackEvents();
  const { mutateAsync: getOAuthUrl, isPending: isOAuthUrlLoading } = useIntegrationAuthMutation(IntegrationTypes.LINKEDIN);

  const handleClick = async () => {
    try {
      const oauthUrlResponse = await getOAuthUrl();
      if (oauthUrlResponse?.oauth_url) {
        trackEvent(EEvent.INTEGRATION_CONNECTION_INITIATED, {
          integration_identifier: IntegrationTypes.LINKEDIN,
        });
        setIframeUrl(decodeURIComponent(oauthUrlResponse.oauth_url))
      }
    }
    catch (error) {
      addAlert({ type: 'error', message: (error as Error)?.message || 'Error connecting LinkedIn account' });
    }
  }

  if (iframeUrl) {
    return (
      <iframe
        src={iframeUrl}
        width="100%"
        height="700"
        style={{ border: 'none' }}
      />
    )
  }

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Typography variant="h1">LinkedIn</Typography>
      </Grid2>
      <Grid2 size={12} container justifyContent="center">
        <FlatPaper sx={{ textAlign: 'center', padding: 4 }}>
          <LinkedInIcon fontSize="large" sx={{ color: '#0a66c2' }} />
          <Typography variant="h6" fontWeight="normal">Connect your LinkedIn account</Typography>
          <Typography variant="body2" color="text.secondary" marginBottom={2}>In order to use LinkedIn RevDog, you need to connect your LinkedIn account.</Typography>
          <LoadingButton
            isLoading={isOAuthUrlLoading}
            onClick={handleClick}
            variant="contained"
            size="large"
          >
            Connect
          </LoadingButton>
        </FlatPaper>
      </Grid2>
    </Grid2>
  )
}
