import { OAuthProvider, useOAuthSignIn } from '@apis/auth'
import salesforceLogo from '@assets/salesforce-logo.jpeg'
import { useAuthContext } from '@components/auth/auth.ctx'
import ComponentSpinner from '@components/ComponentSpinner'
import { EEvent, useTrackEvents } from '@instrumentation/useTrackEvents'
import { Alert, AlertTitle, Container, Stack, Typography } from '@mui/material'
import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'
import { useEffect, useState } from 'react'
import { z } from 'zod'

const signInWithSalesforceSchema = z.object({
  error: z.string().optional(),
  error_description: z.string().optional(),
  code: z.string().optional(),
})

export const Route = createFileRoute('/signin/salesforce')({
  validateSearch: zodValidator(signInWithSalesforceSchema),
  component: RouteComponent,
})

function RouteComponent() {
  const { trackEvent, identify } = useTrackEvents()
  const { error, error_description, code } = Route.useSearch()
  const [isUserSet, setIsUserSet] = useState(false)
  const { data, isLoading, error: signInError } = useOAuthSignIn(OAuthProvider.SALESFORCE, { code: code || '' })
  const { setUser } = useAuthContext()

  const navigate = Route.useNavigate()

  useEffect(() => {
    if (data && !isLoading && !signInError) {
      setUser(data)
      setIsUserSet(true)
      const userProperties = { email: data.email, first_name: data.first_name, last_name: data.last_name, org_name: data.organization.organization_name }
      identify(data.id, userProperties)
      trackEvent(EEvent.USER_SIGNED_IN, userProperties)
    }
  }, [data, isLoading, signInError])

  useEffect(() => {
    if (isUserSet) {
      navigate({ to: '/', replace: true }) // Navigate after user is set
    }
  }, [isUserSet, navigate])

  if (error) {
    return (
      <Container sx={{ mt: 2 }}>
        <Alert severity='error'>
          <AlertTitle>Error: {error}</AlertTitle>
          {error_description}
        </Alert>
      </Container>
    )
  }

  if (signInError) {
    return (
      <Container sx={{ mt: 2 }}>
        <Alert severity='error'>
          <AlertTitle>Error: {signInError.message}</AlertTitle>
        </Alert>
      </Container>
    )
  }

  return (
    <Container sx={{ mt: 2 }}>
      <Stack gap={2} alignItems='center' justifyContent='center'>
        <img src={salesforceLogo} alt='Salesforce' width={70} style={{ mixBlendMode: 'multiply' }} />
        <Typography variant='h5'>Please wait while we sign you in...</Typography>
        <ComponentSpinner />
      </Stack>
    </Container>
  )
}
