import { OAuthProvider, useOAuthURLSignInMutation } from "@apis/auth"
import salesforceLogo from '@assets/salesforce-logo.jpeg'
import { useAppAlerts } from "@components/AppAlerts"
import SignInButtonPaper from "@components/auth/SignInButtonPaper"
import ComponentSpinner from "@components/ComponentSpinner"
import { Typography } from "@mui/material"
import { useState } from "react"

export default function SignInWithSalesforce() {
  const { addAlert } = useAppAlerts()
  const [loading, setLoading] = useState(false)
  const { mutateAsync: getSalesforceAuthUrl } = useOAuthURLSignInMutation(OAuthProvider.SALESFORCE)

  const handleClick = async () => {
    if (loading) return
    try {
      setLoading(true)
      const response = await getSalesforceAuthUrl()
      window.location.href = response.oauth_url
    } catch (err: any) {
      addAlert({
        message: err?.message ?? 'Failed to get Salesforce auth URL',
        type: 'error',
      })
      setLoading(false)
    }
  }
  return (
    <SignInButtonPaper onClick={handleClick} disabled={loading}>
      <img src={salesforceLogo} alt='Salesforce' width={50} style={{ mixBlendMode: 'multiply' }} />
      {loading && <ComponentSpinner size={18} />}
      {!loading && <Typography variant='body2'>Continue with Salesforce</Typography>}
    </SignInButtonPaper>
  )
}
